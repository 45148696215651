




























import { Component, Mixins, Ref } from "vue-property-decorator";
import { Ewelluser, DefaultEwelluser } from "@/types/ewelluser";
import AxiosMixin from "@/mixins/axiosMixin";
import RulesMixin from "#/mixins/rulesMixin";
import ChangeForm from "@/components/userinfo/info/change/ChangeForm.vue";

@Component({ components: { ChangeForm } })
export default class extends Mixins(AxiosMixin, RulesMixin) {
  @Ref("form") private readonly form!: { validate: () => boolean };

  private user: Ewelluser = DefaultEwelluser();

  /** メールアドレス */ private email = "";

  /** メールアドレスの登録がある */ private emailDisplay = false;

  mounted() {
    /** データ取得 */
    this.postJsonCheck(window.base_url + "/api/accountinfo/get", {}, res => {
      this.user = res.data.ewell_user;
      this.email = this.user.mail_address;
      if (this.email) {
        this.emailDisplay = true;
      }
    });
  }

  //メールアドレス変更
  private async changeEmail() {
    if (
      !(await this.$openConfirm("メールアドレスを登録します。よろしいですか？"))
    ) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/accountinfo/confirm",
      {
        email: this.email
      },
      res => {
        this.$router.push({
          path: "/userinfo/mail/confirm?token=" + res.data.token
        });
      }
    );
  }

  //メールアドレス削除
  private async reset() {
    if (
      !(await this.$openConfirm("メールアドレスを削除します。よろしいですか？"))
    ) {
      return;
    }
    this.postJsonCheck(
      window.base_url + "/api/accountinfo/auth/reset",
      {
        reset_type: 1
      },
      () => {
        this.goBack();
      }
    );
  }

  /** ページを戻る */
  private goBack() {
    if (window.history.length > 1) {
      this.$router.go(-1);
    } else {
      this.$router.replace("/userinfo");
    }
  }

  /** メールアドレスルール */
  private get MailRules(): (string | boolean)[] {
    const inputMatch = /^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/; //半角英数記号のみ
    if (!this.email.match(inputMatch)) {
      return [
        this.required(this.email, "必須です"),
        "半角英数記号で入力してください。"
      ];
    }
    return [this.required(this.email, "必須です")];
  }
}
